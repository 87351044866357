import { useLayoutEffect, useState } from 'react';
import { NavLink } from 'react-router-dom'

const Navigation = () => {
    const [user, setUser] = useState();

    useLayoutEffect(() => {
        let userData;
        if (localStorage.getItem("user")) {
            userData = JSON.parse(localStorage["user"]);
            setUser(userData);
        }
    }, []);

    return (
        <div className="sb2-13">
            <ul className="collapsible" data-collapsible="accordion">
                <li><NavLink exact to="/"> Anasayfa</NavLink>
                </li>
                <li><NavLink to="/payrolls" > Bordrolar</NavLink>
                </li>
                <li style={{ width: '100%', padding: "12px 6px" }}><NavLink to="/tables" > Tablolar</NavLink>
                </li>
                {user?.role_id === 1 &&
                    <li style={{ width: '100%', padding: "12px 6px" }}><NavLink to="/users" > Çalışanlar</NavLink>
                    </li>
                }
            </ul>
        </div>
    )
}

export default Navigation;