import { BrowserRouter, Switch, Route } from "react-router-dom";
import Payrolls from "./pages/content/Payrolls";
import Homepage from "./pages/Homepage";
import AuthLogin from './pages/auth/AuthLogin'
import Tables from "./pages/content/Tables";
import Users from "./pages/content/Users";


const App = () => {

  const user = localStorage.getItem("user");

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Homepage} />
        <Route path="/payrolls" component={Payrolls} />
        <Route path="/tables" component={Tables} />
        <Route path="/users" component={Users} />
        <Route path="/auth-login" component={AuthLogin} />
      </Switch>
    </BrowserRouter>
  )
}

export default App;
