import Head from 'react-helmet'
import { useEffect, useLayoutEffect, useState } from 'react';
import Header from '../../layout/Header'
import Navigation from '../../layout/Navigation'
import axios from 'axios';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

const columns = [
    {
        name: 'İsim',
        selector: row => row.name,
        sortable: true
    },
    {
        name: 'Tarih',
        selector: row => moment.utc(row.date).format("MM/YYYY"),
        sortable: true
    },
    {
        name: 'Bordro',
        selector: row => <a download target="_blank" href={`${process.env.REACT_APP_API_URL}/${row.payroll}`}>Bordroyu Görüntüle</a>,
        sortable: true
    },
];


const Payrolls = () => {

    const [data, setData] = useState();
    const [payrolls, setPayrolls] = useState();
    const [formData, setFormData] = useState({});
    const [activeTab, setActiveTab] = useState(1);
    const [alert, setAlert] = useState();
    const [startDate, setStartDate] = useState(new Date());

    const user = JSON.parse(localStorage["user"]);
    const history = useHistory();

    useEffect(() => {
        if(!localStorage["user"]){
            history.push("/auth-login");
        }
    },[])

    const getUsers = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/users`);
            setData(res.data.payload.users);

        } catch (err) {
            console.error(err);
        }
    };

    const loadData = async () => {
        try {
            let res;
            if (user?.role_id === 1) {
                res = await axios.get(`${process.env.REACT_APP_API_URL}/api/payrolls`);
            } else {
                res = await axios.get(`${process.env.REACT_APP_API_URL}/api/payrolls?user_id=${user.id}`);
            }
            setPayrolls([...res.data.payload.payrolls].map((item) => ({ name: item.fullname, date: item.date, payroll: item.url })));
        } catch (err) {
            console.error(err)
        }
    }

    useEffect(() => {
        if (activeTab === 2 && !data)
            getUsers();
    }, [activeTab])

    useEffect(() => {
        loadData();
    }, [])

    const handleSubmit = async () => {
        try {
            const formdata = new FormData();
            formdata.append("payroll", formData.payroll);
            const file = await axios.post(`${process.env.REACT_APP_API_URL}/api/upload`, formdata);
            await axios.post(`${process.env.REACT_APP_API_URL}/api/payrolls`, { url: file.data.filePath, user_id: formData.user, date: new Date(startDate) })
            setAlert({ type: "success", message: "Bordro girişi başarıyla tamamlandı" });
        } catch (err) {
            console.error(err)
            setAlert({ type: "error", message: "Bir hata oluştu" });
        }
    }

    return (
        <>
            <Head>
                <title>Muhasebe App</title>
                <meta name="description" content="Generated by create next app" />
                <link rel="icon" href="/favicon.ico" />
            </Head>

            <div>
                <Header />
                {/*== BODY CONTNAINER ==*/}
                <div className="container-fluid">
                    <div className="row">
                        <div className="sb2-1">
                            {/*== USER INFO ==*/}
                            <div className="sb2-12" style={{ background: "#171717", color: "#fff" }}>
                                <ul>
                                    <li>
                                        <Navigation />
                                    </li>
                                    <li />
                                </ul>
                            </div>
                        </div>
                        <div className="sb2-2">
                            <div className="form-content" style={{ background: "none" }}>
                                <div key={activeTab} className="form-items">
                                    <div className="page-links">
                                        <a href='#' onClick={() => setActiveTab(1)} className={activeTab === 1 ? "active" : ""}>Bordrolar</a>
                                        {(user && user.role_id === 1) &&
                                            (<a href="#" onClick={() => setActiveTab(2)} className={activeTab === 2 ? "active" : ""}>Bordro Girişi</a>)
                                        }
                                    </div>
                                </div>
                            </div>
                            {alert &&
                                alert?.type === "success" ?
                                <div className="alert alert-success" role="alert">
                                    {alert?.message}
                                </div> :
                                alert?.type === "error" ?
                                    <div className="alert alert-danger" role="alert">
                                        {alert?.message}
                                    </div> : null
                            }
                            {activeTab === 2 &&
                                <form onSubmit={(e) => { e.preventDefault(); handleSubmit() }} className="w-400 mw-full">
                                    <div className="form-group">
                                        <label htmlFor="area-of-specialization" className="required">Kullanıcı</label>
                                        <select onChange={(e) => setFormData(current => ({ ...current, user: e.target.value }))} defaultValue={""} style={{ width: '40%' }} className="form-control" id="area-of-specialization" required="required">
                                            <option value="" disabled="disabled">Seçim yapınız</option>
                                            {data && data.map((item) =>
                                                <option key={item.id} value={item.id}>{item.fullname}</option>
                                            )}
                                        </select>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="picture" className="required">Tarih</label>
                                        <div className="custom-file">
                                            <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} dateFormat="MM/yyyy" showMonthYearPicker />
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="picture" className="required">Bordro PDF</label>
                                        <div className="custom-file">
                                            <input onChange={(e) => setFormData(current => ({ ...current, payroll: e.target.files[0] }))} type="file" id="payroll" required="required" accept='application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf' />
                                        </div>
                                    </div>

                                    <input style={{ background: "#171717" }} className="btn btn-primary" type="submit" value="Gönder" />
                                </form>}
                            {activeTab === 1 &&
                                <div key={payrolls}>
                                    <DataTable
                                        highlightOnHover
                                        columns={columns}
                                        data={payrolls}
                                        pagination
                                    />
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Payrolls;
