import Head from 'react-helmet'
import { useEffect, useState } from 'react';
import Header from '../../layout/Header'
import Navigation from '../../layout/Navigation'
import axios from 'axios';
import "react-datepicker/dist/react-datepicker.css";
import DataTable from 'react-data-table-component';
import { useHistory } from 'react-router-dom';

const Payrolls = () => {

    const columns = [
        {
            name: 'İsim',
            selector: row => row.fullname,
            sortable: true
        },
        {
            name: 'Rol',
            selector: row => row.role?.name === "accountant" ? "Muhasebe" : "Çalışan",
            sortable: true
        },
    ];

    const [data, setData] = useState();
    const [payrolls, setPayrolls] = useState();
    const [formData, setFormData] = useState({});
    const [activeTab, setActiveTab] = useState(1);
    const [alert, setAlert] = useState();

    const user = JSON.parse(localStorage["user"]);

    const loadData = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/users`);
            setData(res.data.payload.users);
        } catch (err) {
            console.error(err);
        }
    };

    const history = useHistory();

    useEffect(() => {
        if(!localStorage["user"]){
            history.push("/auth-login")
        }else loadData();
    },[])

    const handleSubmit = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/users`, formData);
            setAlert({ type: "success", message: "Çalışan girişi başarıyla tamamlandı" });
            loadData();
        } catch (err) {
            console.error(err)
            setAlert({ type: "error", message: "Bir hata oluştu" });
        }
    }

    return (
        <>
            <Head>
                <title>Muhasebe App</title>
                <meta name="description" content="Generated by create next app" />
                <link rel="icon" href="/favicon.ico" />
            </Head>

            <div>
                <Header />
                {/*== BODY CONTNAINER ==*/}
                <div className="container-fluid">
                    <div className="row">
                        <div className="sb2-1">
                            {/*== USER INFO ==*/}
                            <div className="sb2-12" style={{ background: "#171717", color: "#fff" }}>
                                <ul>
                                    <li>
                                        <Navigation />
                                    </li>
                                    <li />
                                </ul>
                            </div>
                        </div>
                        <div className="sb2-2">
                            <div className="form-content" style={{ background: "none" }}>
                                <div key={activeTab} className="form-items">
                                    <div className="page-links">
                                        <a href='#' onClick={() => setActiveTab(1)} className={activeTab === 1 ? "active" : ""}>Çalışanlar</a>
                                        <a href="#" onClick={() => setActiveTab(2)} className={activeTab === 2 ? "active" : ""}>Çalışan Girişi</a>

                                    </div>
                                </div>
                            </div>
                            {alert &&
                                alert?.type === "success" ?
                                <div className="alert alert-success" role="alert">
                                    {alert?.message}
                                </div> :
                                alert?.type === "error" ?
                                    <div className="alert alert-danger" role="alert">
                                        {alert?.message}
                                    </div> : null
                            }
                            {activeTab === 2 &&
                                <form onSubmit={(e) => { e.preventDefault(); handleSubmit() }} className="w-400 mw-full">
                                    <div className="form-group">
                                        <label htmlFor="fullname" className="required">İsim Soyisim</label>
                                        <input onChange={(e) => setFormData(current => ({ ...current, fullname: e.target.value }))} type="text" name="fullname" id="fullname" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="password" className="required">Şifre</label>
                                        <input onChange={(e) => setFormData(current => ({ ...current, password: e.target.value }))} type="password" name="password" id="password" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="area-of-specialization" className="required">Rol</label>
                                        <select onChange={(e) => setFormData(current => ({ ...current, role_id: e.target.value }))} defaultValue={""} style={{ width: '40%' }} className="form-control" id="area-of-specialization" required="required">
                                            <option value="" disabled="disabled">Seçim yapınız</option>
                                            <option value={1}>Muhasebe</option>
                                            <option value={2}>Çalışan</option>
                                        </select>
                                    </div>

                                    <input style={{ background: "#171717" }} className="btn btn-primary" type="submit" value="Gönder" />
                                </form>}
                            {activeTab === 1 &&
                                <div key={payrolls}>
                                    <DataTable
                                        columns={columns}
                                        data={data}
                                        pagination
                                    />
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Payrolls;
